<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

		<div v-if="!loadingData">

            <a-row :gutter="24" class="px-10 pr-24 mt-10 pt-10 mb-5">
                <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-20">
                    <a-card style="background-color: #FFFFFF;">
                        <a-col :span="24" :md="12" :lg="12" :xl="12" class="mb-0 pb-0">
                            <span>Revenue this Month 💰</span> <h5 class="mb-0"> TZS {{ totalEarning.toLocaleString() }}/=</h5>
                        </a-col>

                        <a-col :span="24" :md="12" :lg="12" :xl="12" class="mb-0 pb-0 text-right">
                            <span>Balance</span> <h5 class="mb-0">TZS {{ balance }}</h5>
                            <!-- <a-button type="primary" size="small">
                                Withdraw <font-awesome-icon icon="fa-solid fa-chevron-right" class="pr-0 mr-0 ml-10"/><font-awesome-icon icon="fa-solid fa-chevron-right" class="ml-0 pl-0 mr-0 pr-0"/>
                            </a-button> -->
                        </a-col>
                    </a-card>
                </a-col>
            </a-row>

            <a-row :gutter="24" class="px-10 pr-24 mt-20 pt-20 mb-5">
                <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-20">
                    <a-card>
                        <a-row :gutter="24" class="mb-0 pb-0 pt-5">
                            <a-col :span="24" :md="14" :lg="14" :xl="14" class="mb-0 pb-0">
                                <h6>{{ loadingClassesData ? '' : classes.length }} Group Classes</h6>
                            </a-col>
                            <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                                <h6 style="font-weight: normal;"><small>TZS</small> {{ classEarnings.toLocaleString() }}/=</h6>
                            </a-col>
                        </a-row>
                        
                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    v-if="loadingClassesData || (!loadingClassesData && classes.length > 0)"
                                    class="ant-table-striped"
                                    :columns="classColumns" 
									:data-source="classes"
									:loading="loadingClassesData" 
									:pagination="false" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;"
                                    :scroll="{ x: windowWidth < 660 ? 1300 : 0 }">

                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ index + 1 }}
                                        </template>

                                        <template slot="classTitle" slot-scope="row"> 
                                            <a-avatar :src="row.thumbnail" shape="square" class="mr-5"/>
                                           {{ formatName(row.name) }}
                                        </template>

                                        <template slot="instructor" slot-scope="row"> 
                                            {{ `${row.creator.firstName} ${row.creator.lastName}` }}
                                        </template>

                                        <template slot="students" slot-scope="row"> 
                                            <!-- {{ row.studentNo }} -->
                                            {{ row.members.length != 0 ? row.members.length - 1 : 0 }}
                                        </template>

                                        <template slot="revenue" slot-scope="row"> 
                                            {{ row.price != null && row.price != 0 ? (row.price * (row.members.length != 0 ? row.members.length - 1 : 0)).toLocaleString() : 0 }}/=
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="viewItem(row, 'classes')"
                                                style="border-radius: 20px;">
                                                <a-icon type="eye" theme="outlined" />
                                            </a-button>
                                        </template>

                                </a-table>
                            </a-col>
                        </a-row>


                        <div v-if="!loadingClassesData && classes.length == 0">

                            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 20vh">

                                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                                    <img src="../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

                                    <h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">
                                        You do not have any records on classes, create more classes
                                    </h6>

                                    <router-link to="/my-classes/create-new-class">
                                        <a-button id="textBtn" type="text">Create class</a-button>
                                    </router-link>
                                    
                                </a-col>

                            </a-row>
                        </div>

                        <!-- <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh" v-if="classes.length == 0">

                            <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                                <p>You do not have any class yet create your first class to start earning revenue</p>


                                <a-button type="text" href="/my-classes/create-new-class">Create Class</a-button>

                            </a-col>

                        </a-row> -->

                        <a-row :gutter="24" class="mb-0 pb-10 mt-20">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0 text-right">
                                <router-link to="/tutor-classes">
                                    <a-button type="primary" size="small" style="">
                                        All Classes 
                                        <svg class="text-white ml-5" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                                        </svg>
                                    </a-button>
                                </router-link>
                            </a-col>
                        </a-row>
                        
                    </a-card>
                </a-col>

                <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-20 mb-20">
                    <a-card>
                        <a-row :gutter="24" class="mb-0 pb-0 pt-5">
                            <a-col :span="24" :md="14" :lg="14" :xl="14" class="mb-0 pb-0">
                                <h6>{{ loadingQuizzesData ? '' : quizzes.length }} Quizzes Classes</h6>
                            </a-col>
                            <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                                <h6 style="font-weight: normal;"><small>TZS</small> {{ quizEarnings.toLocaleString() }}/=</h6>
                            </a-col>
                        </a-row>

                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    v-if="loadingQuizzesData || (!loadingQuizzesData && quizzes.length > 0)"
                                    class="ant-table-striped"
                                    :columns="quizColumns" 
									:data-source="quizzes"
									:loading="loadingQuizzesData" 
									:pagination="false" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;"
                                    :scroll="{ x: windowWidth < 660 ? 1300 : 0 }">

                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ index + 1 }}
                                        </template>

                                        <template slot="quizTitle" slot-scope="row"> 
                                            <a-avatar :src="row.thumbnail" shape="square" class="mr-5"/>
                                           {{ formatName(row.title) }}
                                        </template>

                                        <template slot="instructor" slot-scope="row">
                                            {{ `${row.creator.firstName} ${row.creator.lastName}` }}
                                        </template>

                                        <template slot="students" slot-scope="row"> 
                                            <!-- {{ row.studentNo }} -->
                                            {{ row.attemptsCount }}
                                        </template>

                                        <template slot="revenue" slot-scope="row"> 
                                            {{ row.price != null && row.price != 0 ? (row.price * row.attemptsCount).toLocaleString() : 0 }}/=
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="viewItem(row, 'quizzes')"
                                                style="border-radius: 20px;">
                                                <a-icon type="eye" theme="outlined" />
                                            </a-button>
                                        </template>

                                </a-table>
                            </a-col>
                        </a-row>

                        <div v-if="!loadingQuizzesData && quizzes.length == 0">

                            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 20vh">

                                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                                    <img src="../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

                                    <h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">
                                        You do not have any records on quizzes, create more quizzes
                                    </h6>

                                    <router-link to="/tutor-quizzes/create">
                                        <a-button id="textBtn" type="text">Create quiz</a-button>
                                    </router-link>
                                    
                                </a-col>

                            </a-row>
                        </div>

                        <a-row :gutter="24" class="mb-0 pb-10 mt-20">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0 text-right">
                                <router-link to="/tutor-quizzes">
                                    <a-button type="primary" size="small" style="">
                                        All Quizzes 
                                        <svg class="text-white ml-5" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                                        </svg>
                                    </a-button>
                                </router-link>
                            </a-col>
                        </a-row>


                    </a-card>
                </a-col>

                <a-col :span="24" :md="24" :lg="24" :xl="24" class="mt-20">
                    <a-card>
                        <a-row :gutter="24" class="mb-0 pb-0 pt-5">
                            <a-col :span="24" :md="14" :lg="14" :xl="14" class="mb-0 pb-0">
                                <h6>{{ loadingNotesData ? '' : notes.length }} Notes</h6>
                            </a-col>
                            <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                                <h6 style="font-weight: normal;"><small>TZS</small> {{ noteEarnings.toLocaleString() }}/=</h6>
                            </a-col>
                        </a-row>

                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    v-if="loadingNotesData || (!loadingNotesData && notes.length > 0)"
                                    class="ant-table-striped"
                                    :columns="noteColumns" 
									:data-source="notes"
									:loading="loadingNotesData" 
									:pagination="false" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;"
                                    :scroll="{ x: windowWidth < 660 ? 1300 : 0 }">

                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ index + 1 }}
                                        </template>

                                        <template slot="classTitle" slot-scope="row"> 
                                            <a-avatar :src="row.thumbnail" shape="square" class="mr-5"/>
                                           {{ formatName(row.title) }}
                                        </template>

                                        <template slot="instructor" slot-scope="row"> 
                                            {{ `${row.creator.firstName} ${row.creator.lastName}` }}
                                        </template>

                                        <template slot="students" slot-scope="row"> 
                                            <!-- {{ row.studentNo }} -->
                                            {{ row.completed.length }}
                                        </template>

                                        <template slot="revenue" slot-scope="row"> 
                                            {{ row.price != null ? (row.completed.length * row.price).toLocaleString() : 0 }}/=
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="onViewFile(row)"
                                                style="border-radius: 20px;">
                                                <a-icon type="eye" theme="outlined" />
                                            </a-button>
                                        </template>

                                </a-table>
                            </a-col>
                        </a-row>


                        <div v-if="!loadingNotesData && notes.length == 0">

                            <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="px-20" style="min-height: 20vh">

                                <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

                                    <img src="../../../public/images/no_data.png" alt="" width="60%" style="" class=""> 

                                    <h6 class="mt-5" style="font-weight: 600; color: #8C8C8C">
                                        You do not have any records on notes, create more notes
                                    </h6>

                                    <router-link to="/my-classes/publish-new-notes">
                                        <a-button id="textBtn" type="text">Create notes</a-button>
                                    </router-link>
                                    
                                </a-col>

                            </a-row>
                        </div>

                        <a-row :gutter="24" class="mb-0 pb-10 mt-20">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0 text-right">
                                <router-link to="/tutor-notes">
                                    <a-button type="primary" size="small" style="">
                                        All Notes 
                                        <svg class="text-white ml-5" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
                                        </svg>
                                    </a-button>
                                </router-link>
                            </a-col>
                        </a-row>

                    </a-card>
                </a-col>

            </a-row>

		</div>


		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>


        <a-drawer
            title="Details"
            placement="right"
            :closable="true"
            :visible="itemDetails.showDrawer"
            @close="onCloseDrawer"
            width="560">
            
            <a-row :gutter="24">
                <a-col :span="24">

                    <a-row :gutter="24">
                        <a-col :span="24">
                            <h5>{{ itemDetails.title }}</h5>
                        </a-col>

                        <a-col :span="24">
                            <img id="classImg" width="100%" height="250px" :src="itemDetails.thumbnail" alt="">
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 px-10" v-if="itemDetails.type == 'classes'">
                        <a-col :span="12">
                            <label><strong>Instructor</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <!-- {{ itemDetails.instructor }} -->
                        </a-col>
                    </a-row>

                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Start Date</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.startDate }}
                        </a-col>
                    </a-row> -->

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>{{ itemDetails.type == 'classes' ? 'Members' : itemDetails.type == 'notes' ? 'Completed' : 'Attempts' }}</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.members }}
                        </a-col>
                    </a-row>

                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Completed the course</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.courseCompletition }}
                        </a-col>
                    </a-row> -->

                    <a-row :gutter="24" class="mt-20 px-10" v-if="itemDetails.type == 'classes'">
                        <a-col :span="12">
                            <label><strong>Slots</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.slots }}
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Price</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <span style="font-size: 10px; font-weight: 600;" v-if="itemDetails.price != 0 && itemDetails.price != null">TZS </span> {{ itemDetails.price != 0 && itemDetails.price != null ? itemDetails.price.toLocaleString() : 'Free' }}
                        </a-col>
                    </a-row>

                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Total Revenue</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <span style="font-size: 10px; font-weight: 600;">TZS </span> {{ itemDetails.totalRevenue }}
                        </a-col>
                    </a-row> -->

                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Tutor’s Rating</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.totalRating }}
                        </a-col>
                    </a-row> -->
                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Education System</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.educationSystem.name }}
                        </a-col>
                    </a-row> -->

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Education Level</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ itemDetails.educationLevel.name }}
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Grades</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <a-badge 
                                v-for="option in itemDetails.grades"
                                :key="option.uuid"
                                style="font-size: 12px !important;" 
                                :count="`${option.name}`" 
                                :number-style="{backgroundColor: '#DBE2ED !important', color: '#414141', paddingTop: '2px', paddingRight: '10px', paddingLeft: '10px', height: '25px', fontSize: '12px'}"/>
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Subjects</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <a-badge 
                                v-for="option in itemDetails.subjects"
                                :key="option.uuid"
                                style="font-size: 12px !important;" 
                                :count="`${option.name}`" 
                                :number-style="{backgroundColor: '#DBE2ED !important', color: '#414141', paddingTop: '2px', paddingRight: '10px', paddingLeft: '10px', height: '25px', fontSize: '12px'}"/>
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 pt-20 px-10">
                        <a-col :span="24" class="text-center mt-20">
                            <a-button
                                type="primary"
                                class="ml-15"
                                size="small"
                                @click="toManageItemDetails"
                                style="border-radius: 20px;">
                                <a-icon type="setting" theme="outlined" />
                                Manage {{ itemDetails.type == 'classes' ? 'Class' : itemDetails.type == 'notes' ? 'Notes' : 'Quiz'}}
                            </a-button>
                        </a-col>
                    </a-row>

                </a-col>
            </a-row>
        </a-drawer>
        
	</div>
</template>

<script>
    import VueJwtDecode from 'vue-jwt-decode'
	import { notification } from 'ant-design-vue';

    const classColumns = [
        {
            title: 'S/n',
            scopedSlots: { customRender: 's_no' },
            width: 40
        },
        {
            title: 'Class title',
            scopedSlots: { customRender: 'classTitle' },
            width: 200
        },
        {
            title: 'Instructor',
            scopedSlots: { customRender: 'instructor' },
            width: 200
        },
        {
            title: '# of Students',
            scopedSlots: { customRender: 'students' },
            key: 'sudents',
            width: 100
        },
        {
            title: 'Revenue',
            scopedSlots: { customRender: 'revenue' },
            width: 100
        },
        {
            title: 'View',
            scopedSlots: { customRender: 'action' },
            width: 100
        },
    ];


    const quizColumns = [
        {
            title: 'S/n',
            scopedSlots: { customRender: 's_no' },
            width: 40
        },
        {
            title: 'Quiz title',
            scopedSlots: { customRender: 'quizTitle' },
            width: 200
        },
        {
            title: 'Instructor',
            scopedSlots: { customRender: 'instructor' },
            width: 200
        },
        {
            title: '# of Attempts',
            scopedSlots: { customRender: 'students' },
            key: 'students',
            width: 100
        },
        {
            title: 'Revenue',
            scopedSlots: { customRender: 'revenue' },
            width: 100
        },
        {
            title: 'View',
            scopedSlots: { customRender: 'action' },
            width: 100
        },
    ];


    const noteColumns = [
        {
            title: 'S/n',
            scopedSlots: { customRender: 's_no' },
            width: 40
        },
        {
            title: 'Class title',
            scopedSlots: { customRender: 'classTitle' },
            width: 200
        },
        {
            title: 'Instructor',
            scopedSlots: { customRender: 'instructor' },
            width: 200
        },
        {
            title: '# of Students',
            scopedSlots: { customRender: 'students' },
            key: 'sudents',
            width: 100
        },
        {
            title: 'Revenue',
            scopedSlots: { customRender: 'revenue' },
            width: 100
        },
        {
            title: 'View',
            scopedSlots: { customRender: 'action' },
            width: 100
        },
    ];

	export default ({
		

		data() {
			return {

                windowWidth: window.innerWidth,
				loadingData: false,
                classColumns,
                quizColumns,
                noteColumns,

				classes: [],
                notes: [],
                quizzes: [],
				currentPage: 1,
                pagination: {
					search: '',
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

                totalEarning: 0,
                balance: 0,

                classEarnings: 0,

                quizEarnings: 0,

                noteEarnings: 0,

                itemDetails: {
                    uuid: null,
                    title: null,
                    thumbnail: null,
                    instructor: null,
                    startDate: null,
                    members: null,
                    courseCompletition: null,
                    educationLevel: {},
                    educationSystem: {},
                    subjects: [],
                    category: {},
                    slots: null,
                    price: null,
                    totalRevenue: null,
                    totalRating: null,
                    label: null,
                    type: null,
                    showDrawer: false,
                },

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    navigate: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },
      		}
		},
        mounted() {
			this.$nextTick(() => {
				window.addEventListener('resize', this.onResize);
			})
		},

		beforeDestroy() { 
			window.removeEventListener('resize', this.onResize); 
		},
		created() {
			this.getTutorReports()
            this.getClasses();
            this.getNotes();
            this.getQuizzes();
		},
		methods: {

            formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

            onResize() {
				this.windowWidth = window.innerWidth
			},


            toCreateClassPage() {
                this.$router.push('/my-classes/create-new-class')
            },

            toCreateNotePage() {
                this.$router.push('/my-classes/publish-new-notes')
            },

            toCreateQuizPage() {
                this.$router.push('/my-classes/create-new-quiz')
            },


            viewItem(item, type) {
                this.itemDetails.uuid = item.uuid;
                this.itemDetails.title = type == 'classes' ? item.name : item.title;
                this.itemDetails.thumbnail = item.thumbnail;
                // this.itemDetails.instructor = item.coTutors;
                this.itemDetails.startDate = item.dateTime != null ? this.$Moment(item.dateTime).format('DD MMM YYYY') : item.dateTime;
                this.itemDetails.members = type == 'classes' ? item.members.length : type == 'notes' ? item.completed.length : item.attempts.length;
                this.itemDetails.educationSystem = item.educationSystems != null && item.educationSystems.length > 0 ? item.educationSystems[0] : {};
                this.itemDetails.educationLevel = item.educationLevels != null && item.educationLevels.length > 0 ? item.educationLevels[0] : {};
                this.itemDetails.grades = item.educationGrades;
                this.itemDetails.subjects = item.subjects;
                this.itemDetails.category = item.category;
                // this.itemDetails.courseCompletition = '4';
                this.itemDetails.slots = item.numOfSlots;
                this.itemDetails.price = item.price;
                this.itemDetails.type = type;
                // this.itemDetails.totalRevenue = item.price;
                // this.itemDetails.totalRating = item.rating != null ? item.rating : 5;
                this.itemDetails.label = null
                this.itemDetails.showDrawer = true;

                // this.category.category = clazz.ca
            },


            onCloseDrawer() {
                this.itemDetails.showDrawer = false;
                this.itemDetails.uuid = null;
                this.itemDetails.title = null;
                this.itemDetails.thumbnail = null;
                this.itemDetails.instructor = null;
                this.itemDetails.startDate = null;
                this.itemDetails.members = null;
                this.itemDetails.courseCompletition = null;
                this.itemDetails.price = null;
                this.itemDetails.totalRevenue = null;
                this.itemDetails.totalRating = null;
                this.itemDetails.label = null;
            },


            toManageItemDetails() {
                if(this.itemDetails.type == 'classes') {
                    this.$router.push(`/my-classes/view/${this.itemDetails.uuid}`)
                }

                if(this.itemDetails.type == 'quizzes') {
                    this.$router.push(`/tutor-quizzes/view/${this.itemDetails.uuid}`)
                }

                // if(this.itemDetails.type == 'notes') {
                //     this.$router.push(`/institutions/my-notes/view/${this.itemDetails.uuid}`)
                // }
            },

            async onViewFile(item) {

                await localStorage.setItem("notesUuid", item.uuid);
				
                if((item.content.path != null && item.content.path.includes('.pdf')) || (item.thumbnail != null && item.thumbnail.includes('.pdf'))) {
                    await localStorage.setItem("pdfFilePath", item.content.path)
                    await localStorage.setItem("pdfFileName", item.thumbnail)

                    window.open(`/pdf-viewer`, '_blank');
                }
                else if((item.content.path != null && item.content.path.includes('.doc')) || (item.thumbnail != null && item.thumbnail.includes('.doc'))) {
                    await localStorage.setItem("docFilePath", item.content.path)
                    await localStorage.setItem("docFileName", item.thumbnail)

                    window.open(`/doc-viewer`, '_blank');
                }
                else if((item.content.path != null && item.content.path.includes('.mkv')) || (item.content.path != null && item.content.path.includes('.mp4'))){
                    await localStorage.setItem("videoFilePath", item.content.path)
                    await localStorage.setItem("videoFileName", item.thumbnail)

                    window.open(`/video-player`, '_blank');
                }
                else{
                    console.log(item.content)

                    this.notify('File format currently is not supported', 'error')
                }
				// this.$router.push(`/pdf-viewer`);
			},


			async getTutorReports() {

				this.loadingData = true;

                let userInfo = await localStorage.getItem("user_details")

				let userDetails = userInfo != null ? JSON.parse(userInfo) : {}
                // uuid=${userDetails.uuid}&
                let url = `${this.$BACKEND_URL}/payments/reports?uuid=${userDetails.uuid}&populate=true&isForInstitution=false`;
                
                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        if(response.data.data != null && response.data.data.length > 0) {
                            response.data.data.forEach(element => {

                                if(element.price != null) {
                                    this.totalEarning += element.price;
                                }

                                if(element.isClazz && element.price != null) {
                                    this.classEarnings += element.price;
                                    this.classes.push(element);
                                }

                                if(element.isMaterial && element.price != null) {
                                    this.noteEarnings += element.price;
                                    this.notes.push(element);
                                }

                                if(element.isQuiz && element.price != null) {
                                    this.quizEarnings += element.price;
                                    this.quizzes.push(element);
                                }
                            });
                        }
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getClasses() {

				this.loadingClassesData = true;

                const currentPage = 1;
                const perPage = 5;

                let userInfo = await localStorage.getItem("user_details");

				let userDetails = userInfo != null ? JSON.parse(userInfo) : {};

                let url = `${this.$BACKEND_URL}/classes?creator=${userDetails.uuid}&page=${currentPage}&size=${perPage}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classes = response.data.data;
                    }
					
					this.loadingClassesData = false;
					
                }).catch(async(error) => {
					
					this.loadingClassesData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getNotes() {

				this.loadingNotesData = true;

                const currentPage = 1;
                const perPage = 5;

                let userInfo = await localStorage.getItem("user_details");

				let userDetails = userInfo != null ? JSON.parse(userInfo) : {};

                let url = `${this.$BACKEND_URL}/materials?creator=${userDetails.uuid}&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.notes = response.data.data;
                    }
					
					this.loadingNotesData = false;
					
                }).catch(async(error) => {
					
					this.loadingNotesData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getQuizzes() {

				this.loadingQuizzesData = true;

                const currentPage = 1;
                const perPage = 5;

                let userInfo = await localStorage.getItem("user_details");

				let userDetails = userInfo != null ? JSON.parse(userInfo) : {};

                let url = `${this.$BACKEND_URL}/quizzes?creator=${userDetails.uuid}&page=${currentPage}&size=${perPage}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.quizzes = response.data.data;
                    }
					
					this.loadingQuizzesData = false;
					
                }).catch(async(error) => {
					
					this.loadingQuizzesData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },





            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },

         

		},
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

a:hover {
    color: inherit !important;
}

#classImg {
	/* border-radius: 10px; */
}

div.ant-select-selection, .ant-select-selection--single {
	border: none !important;
}


div.scrollmenu {
  /* background-color: #333; */
  overflow: auto;
  white-space: nowrap;
  /* overflow: hidden; */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  
}

div.scrollmenu > div {
  display: inline-block;
  color: white;
  /* text-align: center; */
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}

.ant-table-thead .ant-table-cell {
  background-color: green !important;
  }
</style>